<template>
    <footer class="footer-wrapper" role="contentinfo">
        <div class="footer">
            <div class="social-wrapper">
                <h3 class=""><s>Stalk</s> Follow Me &#x28;&nbsp;&#x361;&#xB0;&nbsp;&#x35C;&#x296;&nbsp;&#x361;&#xB0;&#x29;</h3>
                <ul class="social-list">
                    <li class="social-item steam"><a href="https://steamcommunity.com/id/exakayem" target="_blank"></a></li>
                    <li class="social-item twitch"><a href="https://www.twitch.tv/exakayem" target="_blank"></a></li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>

</script>