<template>
    <nav role="navigation" class="navi-wrapper">
        <ul class="main-navi">
            <li class="active">
                <router-link to="/">Home</router-link>
            </li>
            <li :class="{hasSub: true, active: this.activeNavi === 'Games' }">
                <router-link 
                custom
                v-slot="{ route }"
                to="/games">
                <a :data-subnavi="route.name" :href="route.path" @click="toggleActive($event)">{{ route.name }}</a>
                </router-link>
                <div class="sub-navi-wrapper">
                    <ul class="sub-navi">
                        <li>
                            <router-link to="/games/csgo">
                                <img class="navi-icon" src="@/assets/img/navi_icon/navi_games_csgo.png" />
                                CS:GO
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/games/pogo">
                                <img class="navi-icon" src="@/assets/img/navi_icon/navi_games_pogo.png" />
                                PoGO
                            </router-link>
                        </li>
                        <li v-if="0">
                            <router-link to="/games/elune">
                                <img class="navi-icon" src="@/assets/img/navi_icon/navi_games_elune.png" />
                                Elune
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/games/valorant">
                                <img class="navi-icon" src="@/assets/img/navi_icon/navi_games_valorant.png" />
                                VALORANT
                            </router-link>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="">
                <router-link to="/exakayem">About me</router-link>
            </li>
            <li class="">
                <router-link to="/contact">Contact</router-link>
            </li>
            <li class="">
                <router-link to="/playground">Playground</router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExaNavbar',
  data:() => ({

  }),
  computed: {
    activeNavi: {
    get(){
        return this.$store.state.activeNavi
    },
    set(id){
        this.$store.commit('setActiveNavi', id)
    }
    }
  },
  methods: {
      toggleActive: function(event){
          event.preventDefault()
          
          const targetEl = event.target
          const parentEl = targetEl.parentNode
          const siblingEl = targetEl.nextSibling.nextSibling
          
        //   console.log(targetEl)
        //   console.log(siblingEl)
        //   console.log(event.target)
        //   console.log(event.target.parentNode)
        //   console.log(document.getElementById('pageOverlay').classList)
          
          if(parentEl.classList.contains('active')) {
            //   parentEl.classList.remove('active')
            //   document.getElementById('pageOverlay').classList.remove('active')
              siblingEl.style.height = "0px"

          }
          else {
            //   parentEl.classList.add('active')
            //   document.getElementById('pageOverlay').classList.add('active')
              siblingEl.style.height = siblingEl.scrollHeight + 'px'
          }
          
          
        //   return false
          

                        // if(areafilterlist.classList.contains('active')){
                        //     //Zeit vorher offen
                        //     areafilterlist.style.height = "0px";
                        //     areafilter.classList.remove('active');
                        //     areafilterlist.classList.remove('active');
                        //     timefilterlist.style.height = timefilterlist.scrollHeight + 'px';
                        //     timefilter.classList.add('active');
                        //     timefilterlist.classList.add('active');
                        // }
          this.activeNavi = (this.activeNavi == targetEl.dataset.subnavi) ? '' : targetEl.dataset.subnavi
      }
  }
});
</script>