import { createStore } from 'vuex'

export default createStore({
  state: {
    activeNavi: ''
  },
  mutations: {
    //synchron - commit
    setActiveNavi(state, id){
      state.activeNavi = id
    }
  },
  actions: {
    //asynchron - dispatch
    setActiveNavi({ commit }, id){
      if(this.state.activeNavi == id) commit('setActiveNavi', null)
      else commit('setActiveNavi', id)
    }
  },
  modules: {
  }
})
