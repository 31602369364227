import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/',
    component: () => import('../views/home.vue'),
    name: 'Home',
    meta: {
        title: 'Exakayem',
        metaTags: [
            {
                name: 'description',
                content: 'Here you can find out about who Exakayem is, what he does, what games Exakayem plays and what drives him.'
            }
        ]
    }
},
{
    path: "/exakayem",
    component: () => import("../views/about.vue"),
    name: "About",

    meta: {
        title: 'Exakayem > About me',
        metaTags: [
          {
            name: 'description',
            content: 'Here you can find out stuff about me, Exakayem.'
          }//,
        //   {
        //     property: 'og:description',
        //     content: 'The home page of our example app.'
        //   }
        ]
      }
},
{
    path: "/contact",
    component: () => import("../views/contact.vue"),
    name: "Contact",
    meta: {title: "Exakayem > Contact", name: "Contact Exakayem"}
},
{
    path: "/playground",
    component: () => import("../views/shenans.vue"),
    name: "Playground",
    meta: {title: "Exakayem > Playground", name: "Little Shenanigans"}
},
{
    path: "/games",
    component: () => import("../views/games.vue"),
    name: "Games",
    meta: {title: "Exakayem > Games", name: "Games"},
    children: [
        {
            path: "csgo",
            component: () => import("../views/games/csgo.vue"),
            name: "CSGO",
            meta: {title: "Exakayem > Games > CS:GO", name: "Counter-Strike: Global Offensive"}
        },
        {
            path: "pogo",
            component: () => import("../views/games/pogo.vue"),
            name: "PoGO",
            meta: {title: "Exakayem > Games > PoGO", name: "Pokémon GO"}
        },
        // {
        //     path: "elune",
        //     component: () => import("../views/games/elune.vue"),
        //     name: "Elune",
        //     meta: {title: "Exakayem > Games > Elune", name: "Elune"}
        // },
        {
            path: "valorant",
            component: () => import("../views/games/valorant.vue"),
            name: "VALORANT",
            meta: {title: "Exakayem > Games > VALORANT", name: "VALORANT"}
        }
    ]
}
// {
//     path: '*',
//     component: () => import('../views/home.vue'),
//     name: 'Bruh.. Not Found!',
//     props: { 'notfound': true }
// }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
